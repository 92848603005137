import React from 'react';
import {PaymentStatus} from '../../../gqlApp/graphql';

const StatusPayCellFormat = ({value}: {value: PaymentStatus}) => {
	switch (value) {
		case PaymentStatus.Success:
			return <>Успешно</>;
		case PaymentStatus.Error:
			return <>Ошибка</>;
		case PaymentStatus.Rejected:
			return <>Отклонено</>;
		case PaymentStatus.Inprogress:
			return <>В процессе</>;
		case PaymentStatus.Creating:
			return <>Создано</>;

		default:
			return <>''</>;
	}
};

export default StatusPayCellFormat;
