import React, {useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {sortBy} from 'lodash';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import {TooltipItem} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {formatDate} from 'utils/chart';
import {graphql} from '../gqlApp';
import {BookedSuppliesStatQueryVariables, BookedSuppliesStatsResponse} from '../gqlApp/graphql';

const BOOKED_SUPPLIES_STAT_QUERY = graphql(`
	query BookedSuppliesStat($input: DateRangeInput!) {
		bookedSuppliesStat(input: $input) {
			items {
				date
				bookedCount
			}
			groupedByTime
		}
	}
`);

export const useBookedSuppliesStatQuery = (variables?: BookedSuppliesStatQueryVariables) => {
	const {data, loading, error} = useQuery(BOOKED_SUPPLIES_STAT_QUERY, {variables});

	return useMemo(() => {
		const makeData = (data?: BookedSuppliesStatsResponse) => {
			const sortedDataByDate = sortBy(data?.items, 'date');

			return {
				labels: sortedDataByDate?.map(({date}) =>
					format(date, formatDate(data), {locale: ru}),
				),
				datasets: [
					{
						label: 'Кол-во автобронирований',
						unit: 'шт',
						type: 'line',
						color: 'rgb(27,56,80)',
						data: sortedDataByDate.map(({bookedCount}) => Math.round(bookedCount)),
						tooltipReplace:
							sortedDataByDate?.map(({bookedCount, date}) => (
								<TooltipItem
									label={'Автобронирования'}
									count={bookedCount}
									groupBy={data?.groupedByTime}
									date={date}
								/>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.bookedSuppliesStat),
			loading,
			error,
		};
	}, [data?.bookedSuppliesStat, error, loading]);
};
