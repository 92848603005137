/* eslint-disable */
import * as types from './graphql';
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
	'\n\tfragment BROADCAST_BASE on BroadcastEntity {\n\t\tid\n\t\tstatus\n\t\timagePath\n\t\tname\n\t\ttext\n\t\trecipientCount\n\t\terrorCount\n\t\tsuccessCount\n\t\tcreatedAt\n\t\tbuttonUrl\n\t\tbuttonText\n\t}\n':
		types.Broadcast_BaseFragmentDoc,
	'\n\tquery Broadcasts($input: PaginationInput!) {\n\t\tbroadcasts(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...BROADCAST_BASE\n\t\t\t}\n\t\t}\n\t}\n':
		types.BroadcastsDocument,
	'\n\tmutation CreateBroadcast($input: CreateBroadcastInput!, $file: Upload) {\n\t\tcreateBroadcast(input: $input, file: $file) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n':
		types.CreateBroadcastDocument,
	'\n\tmutation TestBroadcast($input: TestBroadcastInput!) {\n\t\ttestBroadcast(input: $input)\n\t}\n':
		types.TestBroadcastDocument,
	'\n\tmutation EditBroadcast($input: EditBroadcastInput!, $file: Upload) {\n\t\teditBroadcast(input: $input, file: $file) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n':
		types.EditBroadcastDocument,
	'\n\tmutation RemoveBroadcast($id: Float!) {\n\t\tremoveBroadcast(id: $id) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n':
		types.RemoveBroadcastDocument,
	'\n\tmutation StartBroadcast($id: Float!) {\n\t\tstartBroadcast(id: $id)\n\t}\n':
		types.StartBroadcastDocument,
	'\n\tquery BookedSuppliesStat($input: DateRangeInput!) {\n\t\tbookedSuppliesStat(input: $input) {\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tbookedCount\n\t\t\t}\n\t\t\tgroupedByTime\n\t\t}\n\t}\n':
		types.BookedSuppliesStatDocument,
	'\n\tquery TelegramUsers($input: TelegramUsersArgs!) {\n\t\ttelegramUsers(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tisActive\n\t\t\t\tfirstName\n\t\t\t\tlastName\n\t\t\t\tusername\n\t\t\t\tfirstActionAt\n\t\t\t\tlastActionAt\n\t\t\t}\n\t\t}\n\t}\n':
		types.TelegramUsersDocument,
	'\n\tquery GetUserData($input: TelegramUserInput!) {\n\t\tgetUserData(input: $input) {\n\t\t\tisActive\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tusername\n\t\t\tfirstActionAt\n\t\t\tlastActionAt\n\t\t\tactiveToDate\n\t\t\tisSubscriptionActive\n\t\t\tfreeSupplyCount\n\t\t\tautoPayment\n\t\t}\n\t}\n':
		types.GetUserDataDocument,
	'\n\tquery GetUserPayments($input: TelegramUserPayments!) {\n\t\tgetUserPayments(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tcreatedAt\n\t\t\t\tamount\n\t\t\t\torderId\n\t\t\t\tstatus\n\t\t\t\ttype\n\t\t\t\tautoPayment\n\t\t\t}\n\t\t}\n\t}\n':
		types.GetUserPaymentsDocument,
	'\n\tquery GetWbPaidSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetWbPaidSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tcreatedAt\n\t\t\t\tenabled\n\t\t\t\twarehouseName\n\t\t\t\tsupplyType\n\t\t\t\tcoefficient\n\t\t\t\tsupplyId\n\t\t\t\tsupplyOrderText\n\t\t\t\tstatus\n\t\t\t\tresultStatus\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n':
		types.GetWbPaidSupplyTracksDocument,
	'\n\tquery GetWbSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetWbSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tcreatedAt\n\t\t\t\tenabled\n\t\t\t\twarehouseName\n\t\t\t\tsupplyType\n\t\t\t\tcoefficient\n\t\t\t\tdates\n\t\t\t\tresultStatus\n\t\t\t}\n\t\t}\n\t}\n':
		types.GetWbSupplyTracksDocument,
	'\n\tquery GetOzonSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetOzonSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tenabled\n\t\t\t\tsupplyOrderId\n\t\t\t\tsupplyOrderText\n\t\t\t\tresultStatus\n\t\t\t}\n\t\t}\n\t}\n':
		types.GetOzonSupplyTracksDocument,
	'\n\tmutation UserSetSettings($input: TelegramUserSettingsInput!) {\n\t\tuserSetSettings(input: $input)\n\t}\n':
		types.UserSetSettingsDocument,
	'\n\tmutation DisableWbSupply($input: SupplySettingsInput!) {\n\t\tdisableWbSupply(input: $input)\n\t}\n':
		types.DisableWbSupplyDocument,
	'\n\tmutation DisableOzonSupply($input: SupplySettingsInput!) {\n\t\tdisableOzonSupply(input: $input)\n\t}\n':
		types.DisableOzonSupplyDocument,
	'\n\tmutation DisableWbPaidSupply($input: SupplySettingsInput!) {\n\t\tdisableWbPaidSupply(input: $input)\n\t}\n':
		types.DisableWbPaidSupplyDocument,
	'\n\tmutation DeleteWbPaidSupply($input: SupplyDeleteInput!) {\n\t\tdeleteWbPaidSupply(input: $input)\n\t}\n':
		types.DeleteWbPaidSupplyDocument,
	'\n\tmutation CreateCardSubscriptionPayment($input: TelegramUserCreateSubscriptionPayment!) {\n\t\tcreateCardSubscriptionPayment(input: $input)\n\t}\n':
		types.CreateCardSubscriptionPaymentDocument,
	'\n\tmutation CreateCardSupplyPayment($input: TelegramUserCreateSupplyPayment!) {\n\t\tcreateCardSupplyPayment(input: $input)\n\t}\n':
		types.CreateCardSupplyPaymentDocument,
	'\n\tmutation CreateSuccessSubscriptionPayment($input: TelegramUserCreateSubscriptionPayment!) {\n\t\tcreateSuccessSubscriptionPayment(input: $input)\n\t}\n':
		types.CreateSuccessSubscriptionPaymentDocument,
	'\n\tmutation CreateSuccessSupplyPayment($input: TelegramUserCreateSupplyPayment!) {\n\t\tcreateSuccessSupplyPayment(input: $input)\n\t}\n':
		types.CreateSuccessSupplyPaymentDocument,
	'\n\tmutation Login($input: LoginInput!) {\n\t\tlogin(input: $input) {\n\t\t\tuser {\n\t\t\t\temail\n\t\t\t}\n\t\t\ttoken\n\t\t}\n\t}\n':
		types.LoginDocument,
	'\n\tmutation Registration($input: RegisterInput!) {\n\t\tregistration(input: $input)\n\t}\n':
		types.RegistrationDocument,
	'\n\tmutation Logout {\n\t\tlogout\n\t}\n': types.LogoutDocument,
	'\n\tquery Me {\n\t\tme {\n\t\t\tid\n\t\t\temail\n\t\t\tname\n\t\t}\n\t}\n': types.MeDocument,
	'\n\tquery MainStats {\n\t\tmainStats {\n\t\t\tactiveUserCount\n\t\t\tnotActiveUserCount\n\t\t\tlastMonthActiveUserCount\n\t\t\tlastMonthActionCount\n\t\t}\n\t}\n':
		types.MainStatsDocument,
	'\n\tquery UserRegistrationsStat($input: DateRangeInput!) {\n\t\tuserRegistrationsStat(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tregistrationCount\n\t\t\t\tunsubscribedCount\n\t\t\t}\n\t\t}\n\t}\n':
		types.UserRegistrationsStatDocument,
	'\n\tquery TelegramUsersActionsStats($input: DateRangeInput!) {\n\t\ttelegramUsersActionsStats(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tcount\n\t\t\t}\n\t\t}\n\t}\n':
		types.TelegramUsersActionsStatsDocument,
	'\n\tquery UniqueTelegramUsersActivity($input: DateRangeInput!) {\n\t\tuniqueTelegramUsersActivity(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tcount\n\t\t\t}\n\t\t}\n\t}\n':
		types.UniqueTelegramUsersActivityDocument,
	'\n\tmutation PasswordReset($email: String!) {\n\t\tpasswordReset(email: $email)\n\t}\n':
		types.PasswordResetDocument,
	'\n\tmutation SetNewPassword($input: NewPasswordInput!) {\n\t\tsetNewPassword(input: $input)\n\t}\n':
		types.SetNewPasswordDocument,
	'\n\tfragment PAYMENTS on PaymentsStatTypeV3 {\n\t\tpayments {\n\t\t\tproduct\n\t\t\tamount\n\t\t\tcount\n\t\t}\n\t\ttypeAmount\n\t\ttypeCount\n\t}\n':
		types.PaymentsFragmentDoc,
	'\n\tquery PaymentStatV3($input: DateRangeInput!) {\n\t\tpaymentsStats: paymentStatV3(input: $input) {\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tsumAmount\n\t\t\t\tsumCount\n\t\t\t\tSUPPLY {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t\tSUBSCRIPTION {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t}\n\t\t\titemsSum {\n\t\t\t\tdate\n\t\t\t\tsumAmount\n\t\t\t\tsumCount\n\t\t\t\tSUPPLY {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t\tSUBSCRIPTION {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t}\n\t\t\tsupplySum\n\t\t\tsubscriptionSum\n\t\t\ttotalSum\n\t\t\tgroupedByTime\n\t\t}\n\t}\n':
		types.PaymentStatV3Document,
	'\n\tquery GetUtmStats($input: UtmStatsArgs!) {\n\t\tgetUtmStats(input: $input) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tutmName\n\t\t\t\tutmSource\n\t\t\t\tutmCount\n\t\t\t\tcreatedAt\n\t\t\t\tusersCount\n\t\t\t\tusersWithPayments\n\t\t\t\tpaymentsTotal\n\t\t\t\tamountTotal\n\t\t\t\tutmCost\n\t\t\t\tregistrationCost\n\t\t\t\tpaymentsUserCost\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t}\n':
		types.GetUtmStatsDocument,
	'\n\tmutation UpdateUtmStat($input: UtmStatInput!) {\n\t\tupdateUtmStat(input: $input)\n\t}\n':
		types.UpdateUtmStatDocument,
	'\n\tmutation AddUtmSource($input: UtmStatInput!) {\n\t\taddUtmSource(input: $input)\n\t}\n':
		types.AddUtmSourceDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment BROADCAST_BASE on BroadcastEntity {\n\t\tid\n\t\tstatus\n\t\timagePath\n\t\tname\n\t\ttext\n\t\trecipientCount\n\t\terrorCount\n\t\tsuccessCount\n\t\tcreatedAt\n\t\tbuttonUrl\n\t\tbuttonText\n\t}\n',
): (typeof documents)['\n\tfragment BROADCAST_BASE on BroadcastEntity {\n\t\tid\n\t\tstatus\n\t\timagePath\n\t\tname\n\t\ttext\n\t\trecipientCount\n\t\terrorCount\n\t\tsuccessCount\n\t\tcreatedAt\n\t\tbuttonUrl\n\t\tbuttonText\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery Broadcasts($input: PaginationInput!) {\n\t\tbroadcasts(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...BROADCAST_BASE\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery Broadcasts($input: PaginationInput!) {\n\t\tbroadcasts(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\t...BROADCAST_BASE\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreateBroadcast($input: CreateBroadcastInput!, $file: Upload) {\n\t\tcreateBroadcast(input: $input, file: $file) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation CreateBroadcast($input: CreateBroadcastInput!, $file: Upload) {\n\t\tcreateBroadcast(input: $input, file: $file) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation TestBroadcast($input: TestBroadcastInput!) {\n\t\ttestBroadcast(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation TestBroadcast($input: TestBroadcastInput!) {\n\t\ttestBroadcast(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation EditBroadcast($input: EditBroadcastInput!, $file: Upload) {\n\t\teditBroadcast(input: $input, file: $file) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation EditBroadcast($input: EditBroadcastInput!, $file: Upload) {\n\t\teditBroadcast(input: $input, file: $file) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation RemoveBroadcast($id: Float!) {\n\t\tremoveBroadcast(id: $id) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation RemoveBroadcast($id: Float!) {\n\t\tremoveBroadcast(id: $id) {\n\t\t\t...BROADCAST_BASE\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation StartBroadcast($id: Float!) {\n\t\tstartBroadcast(id: $id)\n\t}\n',
): (typeof documents)['\n\tmutation StartBroadcast($id: Float!) {\n\t\tstartBroadcast(id: $id)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery BookedSuppliesStat($input: DateRangeInput!) {\n\t\tbookedSuppliesStat(input: $input) {\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tbookedCount\n\t\t\t}\n\t\t\tgroupedByTime\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery BookedSuppliesStat($input: DateRangeInput!) {\n\t\tbookedSuppliesStat(input: $input) {\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tbookedCount\n\t\t\t}\n\t\t\tgroupedByTime\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery TelegramUsers($input: TelegramUsersArgs!) {\n\t\ttelegramUsers(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tisActive\n\t\t\t\tfirstName\n\t\t\t\tlastName\n\t\t\t\tusername\n\t\t\t\tfirstActionAt\n\t\t\t\tlastActionAt\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery TelegramUsers($input: TelegramUsersArgs!) {\n\t\ttelegramUsers(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tisActive\n\t\t\t\tfirstName\n\t\t\t\tlastName\n\t\t\t\tusername\n\t\t\t\tfirstActionAt\n\t\t\t\tlastActionAt\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetUserData($input: TelegramUserInput!) {\n\t\tgetUserData(input: $input) {\n\t\t\tisActive\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tusername\n\t\t\tfirstActionAt\n\t\t\tlastActionAt\n\t\t\tactiveToDate\n\t\t\tisSubscriptionActive\n\t\t\tfreeSupplyCount\n\t\t\tautoPayment\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery GetUserData($input: TelegramUserInput!) {\n\t\tgetUserData(input: $input) {\n\t\t\tisActive\n\t\t\tid\n\t\t\tfirstName\n\t\t\tlastName\n\t\t\tusername\n\t\t\tfirstActionAt\n\t\t\tlastActionAt\n\t\t\tactiveToDate\n\t\t\tisSubscriptionActive\n\t\t\tfreeSupplyCount\n\t\t\tautoPayment\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetUserPayments($input: TelegramUserPayments!) {\n\t\tgetUserPayments(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tcreatedAt\n\t\t\t\tamount\n\t\t\t\torderId\n\t\t\t\tstatus\n\t\t\t\ttype\n\t\t\t\tautoPayment\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery GetUserPayments($input: TelegramUserPayments!) {\n\t\tgetUserPayments(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tcreatedAt\n\t\t\t\tamount\n\t\t\t\torderId\n\t\t\t\tstatus\n\t\t\t\ttype\n\t\t\t\tautoPayment\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetWbPaidSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetWbPaidSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tcreatedAt\n\t\t\t\tenabled\n\t\t\t\twarehouseName\n\t\t\t\tsupplyType\n\t\t\t\tcoefficient\n\t\t\t\tsupplyId\n\t\t\t\tsupplyOrderText\n\t\t\t\tstatus\n\t\t\t\tresultStatus\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery GetWbPaidSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetWbPaidSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tcreatedAt\n\t\t\t\tenabled\n\t\t\t\twarehouseName\n\t\t\t\tsupplyType\n\t\t\t\tcoefficient\n\t\t\t\tsupplyId\n\t\t\t\tsupplyOrderText\n\t\t\t\tstatus\n\t\t\t\tresultStatus\n\t\t\t\terror\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetWbSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetWbSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tcreatedAt\n\t\t\t\tenabled\n\t\t\t\twarehouseName\n\t\t\t\tsupplyType\n\t\t\t\tcoefficient\n\t\t\t\tdates\n\t\t\t\tresultStatus\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery GetWbSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetWbSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tcreatedAt\n\t\t\t\tenabled\n\t\t\t\twarehouseName\n\t\t\t\tsupplyType\n\t\t\t\tcoefficient\n\t\t\t\tdates\n\t\t\t\tresultStatus\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetOzonSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetOzonSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tenabled\n\t\t\t\tsupplyOrderId\n\t\t\t\tsupplyOrderText\n\t\t\t\tresultStatus\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery GetOzonSupplyTracks($input: TelegramUserDateRange!) {\n\t\tgetOzonSupplyTracks(input: $input) {\n\t\t\ttotal\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tenabled\n\t\t\t\tsupplyOrderId\n\t\t\t\tsupplyOrderText\n\t\t\t\tresultStatus\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation UserSetSettings($input: TelegramUserSettingsInput!) {\n\t\tuserSetSettings(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation UserSetSettings($input: TelegramUserSettingsInput!) {\n\t\tuserSetSettings(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation DisableWbSupply($input: SupplySettingsInput!) {\n\t\tdisableWbSupply(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation DisableWbSupply($input: SupplySettingsInput!) {\n\t\tdisableWbSupply(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation DisableOzonSupply($input: SupplySettingsInput!) {\n\t\tdisableOzonSupply(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation DisableOzonSupply($input: SupplySettingsInput!) {\n\t\tdisableOzonSupply(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation DisableWbPaidSupply($input: SupplySettingsInput!) {\n\t\tdisableWbPaidSupply(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation DisableWbPaidSupply($input: SupplySettingsInput!) {\n\t\tdisableWbPaidSupply(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation DeleteWbPaidSupply($input: SupplyDeleteInput!) {\n\t\tdeleteWbPaidSupply(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation DeleteWbPaidSupply($input: SupplyDeleteInput!) {\n\t\tdeleteWbPaidSupply(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreateCardSubscriptionPayment($input: TelegramUserCreateSubscriptionPayment!) {\n\t\tcreateCardSubscriptionPayment(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation CreateCardSubscriptionPayment($input: TelegramUserCreateSubscriptionPayment!) {\n\t\tcreateCardSubscriptionPayment(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreateCardSupplyPayment($input: TelegramUserCreateSupplyPayment!) {\n\t\tcreateCardSupplyPayment(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation CreateCardSupplyPayment($input: TelegramUserCreateSupplyPayment!) {\n\t\tcreateCardSupplyPayment(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreateSuccessSubscriptionPayment($input: TelegramUserCreateSubscriptionPayment!) {\n\t\tcreateSuccessSubscriptionPayment(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation CreateSuccessSubscriptionPayment($input: TelegramUserCreateSubscriptionPayment!) {\n\t\tcreateSuccessSubscriptionPayment(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation CreateSuccessSupplyPayment($input: TelegramUserCreateSupplyPayment!) {\n\t\tcreateSuccessSupplyPayment(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation CreateSuccessSupplyPayment($input: TelegramUserCreateSupplyPayment!) {\n\t\tcreateSuccessSupplyPayment(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation Login($input: LoginInput!) {\n\t\tlogin(input: $input) {\n\t\t\tuser {\n\t\t\t\temail\n\t\t\t}\n\t\t\ttoken\n\t\t}\n\t}\n',
): (typeof documents)['\n\tmutation Login($input: LoginInput!) {\n\t\tlogin(input: $input) {\n\t\t\tuser {\n\t\t\t\temail\n\t\t\t}\n\t\t\ttoken\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation Registration($input: RegisterInput!) {\n\t\tregistration(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation Registration($input: RegisterInput!) {\n\t\tregistration(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation Logout {\n\t\tlogout\n\t}\n',
): (typeof documents)['\n\tmutation Logout {\n\t\tlogout\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery Me {\n\t\tme {\n\t\t\tid\n\t\t\temail\n\t\t\tname\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery Me {\n\t\tme {\n\t\t\tid\n\t\t\temail\n\t\t\tname\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery MainStats {\n\t\tmainStats {\n\t\t\tactiveUserCount\n\t\t\tnotActiveUserCount\n\t\t\tlastMonthActiveUserCount\n\t\t\tlastMonthActionCount\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery MainStats {\n\t\tmainStats {\n\t\t\tactiveUserCount\n\t\t\tnotActiveUserCount\n\t\t\tlastMonthActiveUserCount\n\t\t\tlastMonthActionCount\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery UserRegistrationsStat($input: DateRangeInput!) {\n\t\tuserRegistrationsStat(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tregistrationCount\n\t\t\t\tunsubscribedCount\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery UserRegistrationsStat($input: DateRangeInput!) {\n\t\tuserRegistrationsStat(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tregistrationCount\n\t\t\t\tunsubscribedCount\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery TelegramUsersActionsStats($input: DateRangeInput!) {\n\t\ttelegramUsersActionsStats(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tcount\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery TelegramUsersActionsStats($input: DateRangeInput!) {\n\t\ttelegramUsersActionsStats(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tcount\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery UniqueTelegramUsersActivity($input: DateRangeInput!) {\n\t\tuniqueTelegramUsersActivity(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tcount\n\t\t\t}\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery UniqueTelegramUsersActivity($input: DateRangeInput!) {\n\t\tuniqueTelegramUsersActivity(input: $input) {\n\t\t\tgroupedByTime\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tcount\n\t\t\t}\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation PasswordReset($email: String!) {\n\t\tpasswordReset(email: $email)\n\t}\n',
): (typeof documents)['\n\tmutation PasswordReset($email: String!) {\n\t\tpasswordReset(email: $email)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation SetNewPassword($input: NewPasswordInput!) {\n\t\tsetNewPassword(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation SetNewPassword($input: NewPasswordInput!) {\n\t\tsetNewPassword(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment PAYMENTS on PaymentsStatTypeV3 {\n\t\tpayments {\n\t\t\tproduct\n\t\t\tamount\n\t\t\tcount\n\t\t}\n\t\ttypeAmount\n\t\ttypeCount\n\t}\n',
): (typeof documents)['\n\tfragment PAYMENTS on PaymentsStatTypeV3 {\n\t\tpayments {\n\t\t\tproduct\n\t\t\tamount\n\t\t\tcount\n\t\t}\n\t\ttypeAmount\n\t\ttypeCount\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery PaymentStatV3($input: DateRangeInput!) {\n\t\tpaymentsStats: paymentStatV3(input: $input) {\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tsumAmount\n\t\t\t\tsumCount\n\t\t\t\tSUPPLY {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t\tSUBSCRIPTION {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t}\n\t\t\titemsSum {\n\t\t\t\tdate\n\t\t\t\tsumAmount\n\t\t\t\tsumCount\n\t\t\t\tSUPPLY {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t\tSUBSCRIPTION {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t}\n\t\t\tsupplySum\n\t\t\tsubscriptionSum\n\t\t\ttotalSum\n\t\t\tgroupedByTime\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery PaymentStatV3($input: DateRangeInput!) {\n\t\tpaymentsStats: paymentStatV3(input: $input) {\n\t\t\titems {\n\t\t\t\tdate\n\t\t\t\tsumAmount\n\t\t\t\tsumCount\n\t\t\t\tSUPPLY {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t\tSUBSCRIPTION {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t}\n\t\t\titemsSum {\n\t\t\t\tdate\n\t\t\t\tsumAmount\n\t\t\t\tsumCount\n\t\t\t\tSUPPLY {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t\tSUBSCRIPTION {\n\t\t\t\t\t...PAYMENTS\n\t\t\t\t}\n\t\t\t}\n\t\t\tsupplySum\n\t\t\tsubscriptionSum\n\t\t\ttotalSum\n\t\t\tgroupedByTime\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetUtmStats($input: UtmStatsArgs!) {\n\t\tgetUtmStats(input: $input) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tutmName\n\t\t\t\tutmSource\n\t\t\t\tutmCount\n\t\t\t\tcreatedAt\n\t\t\t\tusersCount\n\t\t\t\tusersWithPayments\n\t\t\t\tpaymentsTotal\n\t\t\t\tamountTotal\n\t\t\t\tutmCost\n\t\t\t\tregistrationCost\n\t\t\t\tpaymentsUserCost\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t}\n',
): (typeof documents)['\n\tquery GetUtmStats($input: UtmStatsArgs!) {\n\t\tgetUtmStats(input: $input) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\tutmName\n\t\t\t\tutmSource\n\t\t\t\tutmCount\n\t\t\t\tcreatedAt\n\t\t\t\tusersCount\n\t\t\t\tusersWithPayments\n\t\t\t\tpaymentsTotal\n\t\t\t\tamountTotal\n\t\t\t\tutmCost\n\t\t\t\tregistrationCost\n\t\t\t\tpaymentsUserCost\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation UpdateUtmStat($input: UtmStatInput!) {\n\t\tupdateUtmStat(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation UpdateUtmStat($input: UtmStatInput!) {\n\t\tupdateUtmStat(input: $input)\n\t}\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation AddUtmSource($input: UtmStatInput!) {\n\t\taddUtmSource(input: $input)\n\t}\n',
): (typeof documents)['\n\tmutation AddUtmSource($input: UtmStatInput!) {\n\t\taddUtmSource(input: $input)\n\t}\n'];

export function graphql(source: string) {
	return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
	TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
