/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> =
	| T
	| {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: {input: string; output: string};
	String: {input: string; output: string};
	Boolean: {input: boolean; output: boolean};
	Int: {input: number; output: number};
	Float: {input: number; output: number};
	/** `Date` type as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
	Timestamp: {input: any; output: any};
	/** The `Upload` scalar type represents a file upload. */
	Upload: {input: any; output: any};
};

export type AdministratorEntity = {
	__typename?: 'AdministratorEntity';
	createdAt: Scalars['Timestamp']['output'];
	email: Scalars['String']['output'];
	id: Scalars['Int']['output'];
	name: Scalars['String']['output'];
};

export type BookedStat = {
	__typename?: 'BookedStat';
	bookedCount: Scalars['Float']['output'];
	date: Scalars['Timestamp']['output'];
};

export type BookedSuppliesStatsResponse = {
	__typename?: 'BookedSuppliesStatsResponse';
	groupedByTime: Scalars['String']['output'];
	items: Array<BookedStat>;
};

export type BroadcastEntity = {
	__typename?: 'BroadcastEntity';
	buttonText?: Maybe<Scalars['String']['output']>;
	buttonUrl?: Maybe<Scalars['String']['output']>;
	createdAt: Scalars['Timestamp']['output'];
	errorCount: Scalars['Int']['output'];
	id: Scalars['Int']['output'];
	imagePath?: Maybe<Scalars['String']['output']>;
	name: Scalars['String']['output'];
	recipientCount: Scalars['Int']['output'];
	status: Scalars['String']['output'];
	successCount: Scalars['Int']['output'];
	text: Scalars['String']['output'];
};

export type BroadcastListResponse = {
	__typename?: 'BroadcastListResponse';
	items: Array<BroadcastEntity>;
	total: Scalars['Float']['output'];
};

export type CountByDateStat = {
	__typename?: 'CountByDateStat';
	count: Scalars['Float']['output'];
	date: Scalars['Timestamp']['output'];
};

export type CountStatsResponse = {
	__typename?: 'CountStatsResponse';
	groupedByTime: Scalars['String']['output'];
	items: Array<CountByDateStat>;
};

export type CreateBroadcastInput = {
	buttonText?: InputMaybe<Scalars['String']['input']>;
	buttonUrl?: InputMaybe<Scalars['String']['input']>;
	name: Scalars['String']['input'];
	text: Scalars['String']['input'];
};

export type DateRangeInput = {
	dateFrom: Scalars['Timestamp']['input'];
	dateTo?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type EditBroadcastInput = {
	buttonText?: InputMaybe<Scalars['String']['input']>;
	buttonUrl?: InputMaybe<Scalars['String']['input']>;
	deleteImage?: InputMaybe<Scalars['Boolean']['input']>;
	id: Scalars['Int']['input'];
	name?: InputMaybe<Scalars['String']['input']>;
	text?: InputMaybe<Scalars['String']['input']>;
};

export type LoginInput = {
	email: Scalars['String']['input'];
	password: Scalars['String']['input'];
};

export type LoginResponse = {
	__typename?: 'LoginResponse';
	token: Scalars['String']['output'];
	user: AdministratorEntity;
};

export type MainStatsResponse = {
	__typename?: 'MainStatsResponse';
	activeUserCount: Scalars['Float']['output'];
	lastMonthActionCount: Scalars['Float']['output'];
	lastMonthActiveUserCount: Scalars['Float']['output'];
	notActiveUserCount: Scalars['Float']['output'];
};

export type Mutation = {
	__typename?: 'Mutation';
	addUtmSource: Scalars['Boolean']['output'];
	createBroadcast: BroadcastEntity;
	createCardSubscriptionPayment: Scalars['String']['output'];
	createCardSupplyPayment: Scalars['String']['output'];
	createSuccessSubscriptionPayment: Scalars['Boolean']['output'];
	createSuccessSupplyPayment: Scalars['Boolean']['output'];
	deleteWbPaidSupply: Scalars['Boolean']['output'];
	disableOzonSupply: Scalars['Boolean']['output'];
	disableWbPaidSupply: Scalars['Boolean']['output'];
	disableWbSupply: Scalars['Boolean']['output'];
	editBroadcast: BroadcastEntity;
	login: LoginResponse;
	logout: Scalars['Boolean']['output'];
	passwordReset: Scalars['String']['output'];
	registration: Scalars['Boolean']['output'];
	removeBroadcast: BroadcastEntity;
	setNewPassword: Scalars['String']['output'];
	startBroadcast: Scalars['Boolean']['output'];
	testBroadcast: Scalars['Boolean']['output'];
	updateUtmStat: Scalars['Boolean']['output'];
	userSetSettings: Scalars['Boolean']['output'];
	userSetVip: Scalars['Boolean']['output'];
};

export type MutationAddUtmSourceArgs = {
	input: UtmStatInput;
};

export type MutationCreateBroadcastArgs = {
	file?: InputMaybe<Scalars['Upload']['input']>;
	input?: InputMaybe<CreateBroadcastInput>;
};

export type MutationCreateCardSubscriptionPaymentArgs = {
	input: TelegramUserCreateSubscriptionPayment;
};

export type MutationCreateCardSupplyPaymentArgs = {
	input: TelegramUserCreateSupplyPayment;
};

export type MutationCreateSuccessSubscriptionPaymentArgs = {
	input: TelegramUserCreateSubscriptionPayment;
};

export type MutationCreateSuccessSupplyPaymentArgs = {
	input: TelegramUserCreateSupplyPayment;
};

export type MutationDeleteWbPaidSupplyArgs = {
	input: SupplyDeleteInput;
};

export type MutationDisableOzonSupplyArgs = {
	input: SupplySettingsInput;
};

export type MutationDisableWbPaidSupplyArgs = {
	input: SupplySettingsInput;
};

export type MutationDisableWbSupplyArgs = {
	input: SupplySettingsInput;
};

export type MutationEditBroadcastArgs = {
	file?: InputMaybe<Scalars['Upload']['input']>;
	input: EditBroadcastInput;
};

export type MutationLoginArgs = {
	input: LoginInput;
};

export type MutationPasswordResetArgs = {
	email: Scalars['String']['input'];
};

export type MutationRegistrationArgs = {
	input: RegisterInput;
};

export type MutationRemoveBroadcastArgs = {
	id: Scalars['Float']['input'];
};

export type MutationSetNewPasswordArgs = {
	input: NewPasswordInput;
};

export type MutationStartBroadcastArgs = {
	id: Scalars['Float']['input'];
};

export type MutationTestBroadcastArgs = {
	input: TestBroadcastInput;
};

export type MutationUpdateUtmStatArgs = {
	input: UtmStatInput;
};

export type MutationUserSetSettingsArgs = {
	input: TelegramUserSettingsInput;
};

export type MutationUserSetVipArgs = {
	input: StatusInput;
};

export type NewPasswordInput = {
	code: Scalars['String']['input'];
	password: Scalars['String']['input'];
};

export enum OrderDirection {
	Asc = 'ASC',
	Desc = 'DESC',
}

export type OzonSupplyData = {
	__typename?: 'OzonSupplyData';
	/** Активен ли поиск поставки */
	enabled: Scalars['Boolean']['output'];
	/** ID поставки в БД */
	id: Scalars['Int']['output'];
	/** Результат поиска (FOUND, EXPIRED, null) */
	resultStatus?: Maybe<Scalars['String']['output']>;
	/** ID поставки в Ozon */
	supplyOrderId: Scalars['Float']['output'];
	/** Информация о поставке */
	supplyOrderText?: Maybe<Scalars['String']['output']>;
};

export type OzonSupplyDataResponse = {
	__typename?: 'OzonSupplyDataResponse';
	items: Array<OzonSupplyData>;
	total: Scalars['Float']['output'];
};

export type PaginationInput = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentStatTypeV3 = {
	__typename?: 'PaymentStatTypeV3';
	/** Сумма проданных услуг */
	amount: Scalars['Int']['output'];
	/** Количество проданных услуг */
	count: Scalars['Int']['output'];
	/** Наименование услуги */
	product: Scalars['String']['output'];
};

export enum PaymentStatus {
	Creating = 'CREATING',
	Error = 'ERROR',
	Inprogress = 'INPROGRESS',
	Rejected = 'REJECTED',
	Success = 'SUCCESS',
}

export type PaymentsStatTypeV3 = {
	__typename?: 'PaymentsStatTypeV3';
	/** Платежи по типу */
	payments: Array<PaymentStatTypeV3>;
	/** Сумма платежей по типу */
	typeAmount: Scalars['Int']['output'];
	/** Количество платежей по типу */
	typeCount: Scalars['Int']['output'];
};

export type PaymentsStatsResponseV3 = {
	__typename?: 'PaymentsStatsResponseV3';
	/** Период группировки платежей */
	groupedByTime: Scalars['String']['output'];
	/** Данные о платежах выбранный период */
	items: Array<PaymentsStatsV3>;
	/** Сумма данных о платежах выбранный период */
	itemsSum: PaymentsStatsV3;
	/** Сумма всех платежей за выбранный период: подписки */
	subscriptionSum: Scalars['Int']['output'];
	/** Сумма всех платежей за выбранный период: автобронирования */
	supplySum: Scalars['Int']['output'];
	/** Общая сумма всех платежей за выбранный период */
	totalSum: Scalars['Int']['output'];
};

export type PaymentsStatsV3 = {
	__typename?: 'PaymentsStatsV3';
	/** Данные о платежах за выведенную дату: подписки */
	SUBSCRIPTION: PaymentsStatTypeV3;
	/** Данные о платежах за выведенную дату: автобронирования */
	SUPPLY: PaymentsStatTypeV3;
	/** Дата платежей */
	date: Scalars['Timestamp']['output'];
	/** Сумма платежей за выведенную дату */
	sumAmount: Scalars['Int']['output'];
	/** Количество платежей за выведенную дату */
	sumCount: Scalars['Int']['output'];
};

export type Query = {
	__typename?: 'Query';
	bookedSuppliesStat: BookedSuppliesStatsResponse;
	broadcasts: BroadcastListResponse;
	getOzonSupplyTracks: OzonSupplyDataResponse;
	getUserData: TelegramUserEntity;
	getUserPayments: TelegramUserPaymentsResponse;
	getUtmStats: UtmStatsResponse;
	getWbPaidSupplyTracks: WbPaidSupplyDataResponse;
	getWbSupplyTracks: WbSupplyDataResponse;
	mainStats: MainStatsResponse;
	me: AdministratorEntity;
	paymentStatV3: PaymentsStatsResponseV3;
	/** Возвращает статистику платежей через Telegram Stars */
	paymentWithStarsStat: PaymentsStatsResponseV3;
	telegramUsers: TelegramUsersListResponse;
	telegramUsersActionsStats: CountStatsResponse;
	uniqueTelegramUsersActivity: CountStatsResponse;
	userRegistrationsStat: RegistrationsStatsResponse;
};

export type QueryBookedSuppliesStatArgs = {
	input: DateRangeInput;
};

export type QueryBroadcastsArgs = {
	input: PaginationInput;
};

export type QueryGetOzonSupplyTracksArgs = {
	input: TelegramUserDateRange;
};

export type QueryGetUserDataArgs = {
	input: TelegramUserInput;
};

export type QueryGetUserPaymentsArgs = {
	input: TelegramUserPayments;
};

export type QueryGetUtmStatsArgs = {
	input: UtmStatsArgs;
};

export type QueryGetWbPaidSupplyTracksArgs = {
	input: TelegramUserDateRange;
};

export type QueryGetWbSupplyTracksArgs = {
	input: TelegramUserDateRange;
};

export type QueryPaymentStatV3Args = {
	input: DateRangeInput;
};

export type QueryPaymentWithStarsStatArgs = {
	input: DateRangeInput;
};

export type QueryTelegramUsersArgs = {
	input: TelegramUsersArgs;
};

export type QueryTelegramUsersActionsStatsArgs = {
	input: DateRangeInput;
};

export type QueryUniqueTelegramUsersActivityArgs = {
	input: DateRangeInput;
};

export type QueryUserRegistrationsStatArgs = {
	input: DateRangeInput;
};

export type RegisterInput = {
	email: Scalars['String']['input'];
	name: Scalars['String']['input'];
	password: Scalars['String']['input'];
};

export type RegistrationsStat = {
	__typename?: 'RegistrationsStat';
	date: Scalars['Timestamp']['output'];
	registrationCount: Scalars['Float']['output'];
	unsubscribedCount: Scalars['Float']['output'];
};

export type RegistrationsStatsResponse = {
	__typename?: 'RegistrationsStatsResponse';
	groupedByTime: Scalars['String']['output'];
	items: Array<RegistrationsStat>;
};

export type StatusInput = {
	status: Scalars['Boolean']['input'];
	/** ID пользователя в Telegram */
	userId: Scalars['Float']['input'];
};

export type SupplyDeleteInput = {
	/** Нужно ли вернуть ли пользователю автобронирование после удаления */
	compensate: Scalars['Boolean']['input'];
	/** ID поставки */
	supplyId: Scalars['Int']['input'];
};

export type SupplySettingsInput = {
	/** ID поставки */
	supplyId: Scalars['Int']['input'];
};

export type TelegramUserCreateSubscriptionPayment = {
	/** Число дней в подписке или число автоброней к зачислению */
	daysCount: Scalars['Int']['input'];
	/** Число автоброней к зачислению */
	freeSupplyCount: Scalars['Int']['input'];
	/** Цена */
	price: Scalars['Int']['input'];
	/** ID пользователя в Telegram */
	userId: Scalars['Float']['input'];
};

export type TelegramUserCreateSupplyPayment = {
	/** Цена */
	price: Scalars['Int']['input'];
	/** Число автоброней к зачислению */
	supplyCount: Scalars['Int']['input'];
	/** ID пользователя в Telegram */
	userId: Scalars['Float']['input'];
};

export type TelegramUserDateRange = {
	/** Диапазон дат для поиска (Опциональный) */
	dateRange?: InputMaybe<DateRangeInput>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	/** ID пользователя в Telegram */
	userId: Scalars['Float']['input'];
};

export type TelegramUserEntity = {
	__typename?: 'TelegramUserEntity';
	activeToDate: Scalars['Timestamp']['output'];
	autoPayment: Scalars['Boolean']['output'];
	firstActionAt: Scalars['Timestamp']['output'];
	firstName: Scalars['String']['output'];
	freeSupplyCount: Scalars['Int']['output'];
	id: Scalars['Float']['output'];
	isActive: Scalars['Boolean']['output'];
	isSubscriptionActive: Scalars['Boolean']['output'];
	lastActionAt: Scalars['Timestamp']['output'];
	lastName?: Maybe<Scalars['String']['output']>;
	username?: Maybe<Scalars['String']['output']>;
};

export type TelegramUserInput = {
	/** ID пользователя в Telegram */
	userId: Scalars['Float']['input'];
};

export type TelegramUserPayment = {
	__typename?: 'TelegramUserPayment';
	/** Сумма платежа */
	amount: Scalars['Int']['output'];
	/** Является ли платеж автоматическим */
	autoPayment: Scalars['Boolean']['output'];
	/** Дата создания платежа */
	createdAt: Scalars['Timestamp']['output'];
	/** ID платежа */
	orderId?: Maybe<Scalars['String']['output']>;
	/** Статус платежа (CREATING, INPROGRESS, REJECTED, ERROR, SUCCESS) */
	status: Scalars['String']['output'];
	/** Тип платежа (SUBSCRIPTION, SUPPLY_PACKAGE, SUPPLY */
	type: Scalars['String']['output'];
};

export type TelegramUserPayments = {
	/** Диапазон дат для поиска (Опциональный) */
	dateRange?: InputMaybe<DateRangeInput>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	/** Массив статусов платежей для поиска (CREATING, INPROGRESS, REJECTED, ERROR, SUCCESS) */
	paymentStatus?: InputMaybe<Array<PaymentStatus>>;
	/** ID пользователя в Telegram */
	userId: Scalars['Float']['input'];
};

export type TelegramUserPaymentsResponse = {
	__typename?: 'TelegramUserPaymentsResponse';
	/** Массив платежей */
	items: Array<TelegramUserPayment>;
	/** Всего платежей по поиску для пагинации */
	total: Scalars['Float']['output'];
};

export type TelegramUserSettingsInput = {
	/** Дата окончания подписки */
	activeToDate?: InputMaybe<Scalars['Timestamp']['input']>;
	/** Статус автоплатежа */
	autoPayment?: InputMaybe<Scalars['Boolean']['input']>;
	/** Число бесплатных автобронирований */
	freeSupplyCount?: InputMaybe<Scalars['Int']['input']>;
	/** ID пользователя в Telegram */
	userId: Scalars['Float']['input'];
};

export type TelegramUsersArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	page?: InputMaybe<Scalars['Int']['input']>;
	/** Поиск пользователя по ID, имени, фамилии или псевдониму */
	search?: InputMaybe<Scalars['String']['input']>;
};

export type TelegramUsersListResponse = {
	__typename?: 'TelegramUsersListResponse';
	items: Array<TelegramUserEntity>;
	total: Scalars['Float']['output'];
};

export type TestBroadcastInput = {
	id: Scalars['Int']['input'];
	usernames: Array<Scalars['String']['input']>;
};

export enum UtmSortingBy {
	AmountTotal = 'AMOUNT_TOTAL',
	CreatedAt = 'CREATED_AT',
	PaymentsTotal = 'PAYMENTS_TOTAL',
	PaymentsUserCost = 'PAYMENTS_USER_COST',
	RegistrationCost = 'REGISTRATION_COST',
	UsersCount = 'USERS_COUNT',
	UsersWithPayments = 'USERS_WITH_PAYMENTS',
	UtmCost = 'UTM_COST',
	UtmCount = 'UTM_COUNT',
}

export type UtmStatInput = {
	utmCost?: InputMaybe<Scalars['Int']['input']>;
	utmName?: InputMaybe<Scalars['String']['input']>;
	utmSource: Scalars['String']['input'];
};

export type UtmStatResponse = {
	__typename?: 'UtmStatResponse';
	amountTotal: Scalars['Int']['output'];
	createdAt: Scalars['Timestamp']['output'];
	id: Scalars['Int']['output'];
	paymentsTotal: Scalars['Int']['output'];
	paymentsUserCost: Scalars['Float']['output'];
	registrationCost: Scalars['Float']['output'];
	usersCount: Scalars['Int']['output'];
	usersWithPayments: Scalars['Int']['output'];
	utmCost: Scalars['Int']['output'];
	utmCount: Scalars['Int']['output'];
	utmName?: Maybe<Scalars['String']['output']>;
	utmSource: Scalars['String']['output'];
};

export type UtmStatsArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	orderDirection?: InputMaybe<OrderDirection>;
	page?: InputMaybe<Scalars['Int']['input']>;
	search?: InputMaybe<Scalars['String']['input']>;
	utmSortingBy?: InputMaybe<UtmSortingBy>;
};

export type UtmStatsResponse = {
	__typename?: 'UtmStatsResponse';
	items: Array<UtmStatResponse>;
	total: Scalars['Int']['output'];
};

export type WbPaidSupplyData = {
	__typename?: 'WbPaidSupplyData';
	/** Коэффициент приемки к поиску */
	coefficient: Scalars['Int']['output'];
	/** Дата создания поиска поставки */
	createdAt: Scalars['Timestamp']['output'];
	/** Активен ли поиск поставки */
	enabled: Scalars['Boolean']['output'];
	/** Ошибка если не удалось забронировать поставку */
	error?: Maybe<Scalars['String']['output']>;
	/** ID поставки в БД */
	id: Scalars['Int']['output'];
	/** Результат поиска (FOUND, EXPIRED, null) */
	resultStatus?: Maybe<Scalars['String']['output']>;
	/** Статус поиска свободного слота (WBERROR, TIMEOUT, ACTIVE) */
	status: Scalars['String']['output'];
	/** ID поставки в WB */
	supplyId: Scalars['Float']['output'];
	/** Информация о поставке */
	supplyOrderText: Scalars['String']['output'];
	/** Тип поставки */
	supplyType: Scalars['String']['output'];
	/** Название или ID склада */
	warehouseName: Scalars['String']['output'];
};

export type WbPaidSupplyDataResponse = {
	__typename?: 'WbPaidSupplyDataResponse';
	items: Array<WbPaidSupplyData>;
	total: Scalars['Float']['output'];
};

export type WbSupplyData = {
	__typename?: 'WbSupplyData';
	/** Коэффициент приемки к поиску */
	coefficient: Scalars['Int']['output'];
	/** Дата создания поиска поставки */
	createdAt: Scalars['Timestamp']['output'];
	/** Даты приемки к поиску */
	dates: Scalars['String']['output'];
	/** Активен ли поиск поставки */
	enabled: Scalars['Boolean']['output'];
	/** ID поставки в БД */
	id: Scalars['Int']['output'];
	/** Результат поиска (FOUND, EXPIRED, null) */
	resultStatus?: Maybe<Scalars['String']['output']>;
	/** Тип поставки */
	supplyType: Scalars['String']['output'];
	/** Название или ID склада */
	warehouseName: Scalars['String']['output'];
};

export type WbSupplyDataResponse = {
	__typename?: 'WbSupplyDataResponse';
	items: Array<WbSupplyData>;
	total: Scalars['Float']['output'];
};

export type Broadcast_BaseFragment = {
	__typename?: 'BroadcastEntity';
	id: number;
	status: string;
	imagePath?: string | null;
	name: string;
	text: string;
	recipientCount: number;
	errorCount: number;
	successCount: number;
	createdAt: any;
	buttonUrl?: string | null;
	buttonText?: string | null;
};

export type BroadcastsQueryVariables = Exact<{
	input: PaginationInput;
}>;

export type BroadcastsQuery = {
	__typename?: 'Query';
	broadcasts: {
		__typename?: 'BroadcastListResponse';
		total: number;
		items: Array<{__typename?: 'BroadcastEntity'} & Broadcast_BaseFragment>;
	};
};

export type CreateBroadcastMutationVariables = Exact<{
	input: CreateBroadcastInput;
	file?: InputMaybe<Scalars['Upload']['input']>;
}>;

export type CreateBroadcastMutation = {
	__typename?: 'Mutation';
	createBroadcast: {__typename?: 'BroadcastEntity'} & Broadcast_BaseFragment;
};

export type TestBroadcastMutationVariables = Exact<{
	input: TestBroadcastInput;
}>;

export type TestBroadcastMutation = {__typename?: 'Mutation'; testBroadcast: boolean};

export type EditBroadcastMutationVariables = Exact<{
	input: EditBroadcastInput;
	file?: InputMaybe<Scalars['Upload']['input']>;
}>;

export type EditBroadcastMutation = {
	__typename?: 'Mutation';
	editBroadcast: {__typename?: 'BroadcastEntity'} & Broadcast_BaseFragment;
};

export type RemoveBroadcastMutationVariables = Exact<{
	id: Scalars['Float']['input'];
}>;

export type RemoveBroadcastMutation = {
	__typename?: 'Mutation';
	removeBroadcast: {__typename?: 'BroadcastEntity'} & Broadcast_BaseFragment;
};

export type StartBroadcastMutationVariables = Exact<{
	id: Scalars['Float']['input'];
}>;

export type StartBroadcastMutation = {__typename?: 'Mutation'; startBroadcast: boolean};

export type BookedSuppliesStatQueryVariables = Exact<{
	input: DateRangeInput;
}>;

export type BookedSuppliesStatQuery = {
	__typename?: 'Query';
	bookedSuppliesStat: {
		__typename?: 'BookedSuppliesStatsResponse';
		groupedByTime: string;
		items: Array<{__typename?: 'BookedStat'; date: any; bookedCount: number}>;
	};
};

export type TelegramUsersQueryVariables = Exact<{
	input: TelegramUsersArgs;
}>;

export type TelegramUsersQuery = {
	__typename?: 'Query';
	telegramUsers: {
		__typename?: 'TelegramUsersListResponse';
		total: number;
		items: Array<{
			__typename?: 'TelegramUserEntity';
			id: number;
			isActive: boolean;
			firstName: string;
			lastName?: string | null;
			username?: string | null;
			firstActionAt: any;
			lastActionAt: any;
		}>;
	};
};

export type GetUserDataQueryVariables = Exact<{
	input: TelegramUserInput;
}>;

export type GetUserDataQuery = {
	__typename?: 'Query';
	getUserData: {
		__typename?: 'TelegramUserEntity';
		isActive: boolean;
		id: number;
		firstName: string;
		lastName?: string | null;
		username?: string | null;
		firstActionAt: any;
		lastActionAt: any;
		activeToDate: any;
		isSubscriptionActive: boolean;
		freeSupplyCount: number;
		autoPayment: boolean;
	};
};

export type GetUserPaymentsQueryVariables = Exact<{
	input: TelegramUserPayments;
}>;

export type GetUserPaymentsQuery = {
	__typename?: 'Query';
	getUserPayments: {
		__typename?: 'TelegramUserPaymentsResponse';
		total: number;
		items: Array<{
			__typename?: 'TelegramUserPayment';
			createdAt: any;
			amount: number;
			orderId?: string | null;
			status: string;
			type: string;
			autoPayment: boolean;
		}>;
	};
};

export type GetWbPaidSupplyTracksQueryVariables = Exact<{
	input: TelegramUserDateRange;
}>;

export type GetWbPaidSupplyTracksQuery = {
	__typename?: 'Query';
	getWbPaidSupplyTracks: {
		__typename?: 'WbPaidSupplyDataResponse';
		total: number;
		items: Array<{
			__typename?: 'WbPaidSupplyData';
			id: number;
			createdAt: any;
			enabled: boolean;
			warehouseName: string;
			supplyType: string;
			coefficient: number;
			supplyId: number;
			supplyOrderText: string;
			status: string;
			resultStatus?: string | null;
			error?: string | null;
		}>;
	};
};

export type GetWbSupplyTracksQueryVariables = Exact<{
	input: TelegramUserDateRange;
}>;

export type GetWbSupplyTracksQuery = {
	__typename?: 'Query';
	getWbSupplyTracks: {
		__typename?: 'WbSupplyDataResponse';
		total: number;
		items: Array<{
			__typename?: 'WbSupplyData';
			id: number;
			createdAt: any;
			enabled: boolean;
			warehouseName: string;
			supplyType: string;
			coefficient: number;
			dates: string;
			resultStatus?: string | null;
		}>;
	};
};

export type GetOzonSupplyTracksQueryVariables = Exact<{
	input: TelegramUserDateRange;
}>;

export type GetOzonSupplyTracksQuery = {
	__typename?: 'Query';
	getOzonSupplyTracks: {
		__typename?: 'OzonSupplyDataResponse';
		total: number;
		items: Array<{
			__typename?: 'OzonSupplyData';
			id: number;
			enabled: boolean;
			supplyOrderId: number;
			supplyOrderText?: string | null;
			resultStatus?: string | null;
		}>;
	};
};

export type UserSetSettingsMutationVariables = Exact<{
	input: TelegramUserSettingsInput;
}>;

export type UserSetSettingsMutation = {__typename?: 'Mutation'; userSetSettings: boolean};

export type DisableWbSupplyMutationVariables = Exact<{
	input: SupplySettingsInput;
}>;

export type DisableWbSupplyMutation = {__typename?: 'Mutation'; disableWbSupply: boolean};

export type DisableOzonSupplyMutationVariables = Exact<{
	input: SupplySettingsInput;
}>;

export type DisableOzonSupplyMutation = {__typename?: 'Mutation'; disableOzonSupply: boolean};

export type DisableWbPaidSupplyMutationVariables = Exact<{
	input: SupplySettingsInput;
}>;

export type DisableWbPaidSupplyMutation = {__typename?: 'Mutation'; disableWbPaidSupply: boolean};

export type DeleteWbPaidSupplyMutationVariables = Exact<{
	input: SupplyDeleteInput;
}>;

export type DeleteWbPaidSupplyMutation = {__typename?: 'Mutation'; deleteWbPaidSupply: boolean};

export type CreateCardSubscriptionPaymentMutationVariables = Exact<{
	input: TelegramUserCreateSubscriptionPayment;
}>;

export type CreateCardSubscriptionPaymentMutation = {
	__typename?: 'Mutation';
	createCardSubscriptionPayment: string;
};

export type CreateCardSupplyPaymentMutationVariables = Exact<{
	input: TelegramUserCreateSupplyPayment;
}>;

export type CreateCardSupplyPaymentMutation = {
	__typename?: 'Mutation';
	createCardSupplyPayment: string;
};

export type CreateSuccessSubscriptionPaymentMutationVariables = Exact<{
	input: TelegramUserCreateSubscriptionPayment;
}>;

export type CreateSuccessSubscriptionPaymentMutation = {
	__typename?: 'Mutation';
	createSuccessSubscriptionPayment: boolean;
};

export type CreateSuccessSupplyPaymentMutationVariables = Exact<{
	input: TelegramUserCreateSupplyPayment;
}>;

export type CreateSuccessSupplyPaymentMutation = {
	__typename?: 'Mutation';
	createSuccessSupplyPayment: boolean;
};

export type LoginMutationVariables = Exact<{
	input: LoginInput;
}>;

export type LoginMutation = {
	__typename?: 'Mutation';
	login: {
		__typename?: 'LoginResponse';
		token: string;
		user: {__typename?: 'AdministratorEntity'; email: string};
	};
};

export type RegistrationMutationVariables = Exact<{
	input: RegisterInput;
}>;

export type RegistrationMutation = {__typename?: 'Mutation'; registration: boolean};

export type LogoutMutationVariables = Exact<{[key: string]: never}>;

export type LogoutMutation = {__typename?: 'Mutation'; logout: boolean};

export type MeQueryVariables = Exact<{[key: string]: never}>;

export type MeQuery = {
	__typename?: 'Query';
	me: {__typename?: 'AdministratorEntity'; id: number; email: string; name: string};
};

export type MainStatsQueryVariables = Exact<{[key: string]: never}>;

export type MainStatsQuery = {
	__typename?: 'Query';
	mainStats: {
		__typename?: 'MainStatsResponse';
		activeUserCount: number;
		notActiveUserCount: number;
		lastMonthActiveUserCount: number;
		lastMonthActionCount: number;
	};
};

export type UserRegistrationsStatQueryVariables = Exact<{
	input: DateRangeInput;
}>;

export type UserRegistrationsStatQuery = {
	__typename?: 'Query';
	userRegistrationsStat: {
		__typename?: 'RegistrationsStatsResponse';
		groupedByTime: string;
		items: Array<{
			__typename?: 'RegistrationsStat';
			date: any;
			registrationCount: number;
			unsubscribedCount: number;
		}>;
	};
};

export type TelegramUsersActionsStatsQueryVariables = Exact<{
	input: DateRangeInput;
}>;

export type TelegramUsersActionsStatsQuery = {
	__typename?: 'Query';
	telegramUsersActionsStats: {
		__typename?: 'CountStatsResponse';
		groupedByTime: string;
		items: Array<{__typename?: 'CountByDateStat'; date: any; count: number}>;
	};
};

export type UniqueTelegramUsersActivityQueryVariables = Exact<{
	input: DateRangeInput;
}>;

export type UniqueTelegramUsersActivityQuery = {
	__typename?: 'Query';
	uniqueTelegramUsersActivity: {
		__typename?: 'CountStatsResponse';
		groupedByTime: string;
		items: Array<{__typename?: 'CountByDateStat'; date: any; count: number}>;
	};
};

export type PasswordResetMutationVariables = Exact<{
	email: Scalars['String']['input'];
}>;

export type PasswordResetMutation = {__typename?: 'Mutation'; passwordReset: string};

export type SetNewPasswordMutationVariables = Exact<{
	input: NewPasswordInput;
}>;

export type SetNewPasswordMutation = {__typename?: 'Mutation'; setNewPassword: string};

export type PaymentsFragment = {
	__typename?: 'PaymentsStatTypeV3';
	typeAmount: number;
	typeCount: number;
	payments: Array<{
		__typename?: 'PaymentStatTypeV3';
		product: string;
		amount: number;
		count: number;
	}>;
};

export type PaymentStatV3QueryVariables = Exact<{
	input: DateRangeInput;
}>;

export type PaymentStatV3Query = {
	__typename?: 'Query';
	paymentsStats: {
		__typename?: 'PaymentsStatsResponseV3';
		supplySum: number;
		subscriptionSum: number;
		totalSum: number;
		groupedByTime: string;
		items: Array<{
			__typename?: 'PaymentsStatsV3';
			date: any;
			sumAmount: number;
			sumCount: number;
			SUPPLY: {__typename?: 'PaymentsStatTypeV3'} & PaymentsFragment;
			SUBSCRIPTION: {__typename?: 'PaymentsStatTypeV3'} & PaymentsFragment;
		}>;
		itemsSum: {
			__typename?: 'PaymentsStatsV3';
			date: any;
			sumAmount: number;
			sumCount: number;
			SUPPLY: {__typename?: 'PaymentsStatTypeV3'} & PaymentsFragment;
			SUBSCRIPTION: {__typename?: 'PaymentsStatTypeV3'} & PaymentsFragment;
		};
	};
};

export type GetUtmStatsQueryVariables = Exact<{
	input: UtmStatsArgs;
}>;

export type GetUtmStatsQuery = {
	__typename?: 'Query';
	getUtmStats: {
		__typename?: 'UtmStatsResponse';
		total: number;
		items: Array<{
			__typename?: 'UtmStatResponse';
			id: number;
			utmName?: string | null;
			utmSource: string;
			utmCount: number;
			createdAt: any;
			usersCount: number;
			usersWithPayments: number;
			paymentsTotal: number;
			amountTotal: number;
			utmCost: number;
			registrationCost: number;
			paymentsUserCost: number;
		}>;
	};
};

export type UpdateUtmStatMutationVariables = Exact<{
	input: UtmStatInput;
}>;

export type UpdateUtmStatMutation = {__typename?: 'Mutation'; updateUtmStat: boolean};

export type AddUtmSourceMutationVariables = Exact<{
	input: UtmStatInput;
}>;

export type AddUtmSourceMutation = {__typename?: 'Mutation'; addUtmSource: boolean};

export const Broadcast_BaseFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'BROADCAST_BASE'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BroadcastEntity'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'status'}},
					{kind: 'Field', name: {kind: 'Name', value: 'imagePath'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'text'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'errorCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'successCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonText'}},
				],
			},
		},
	],
} as unknown as DocumentNode<Broadcast_BaseFragment, unknown>;
export const PaymentsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'PAYMENTS'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PaymentsStatTypeV3'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'payments'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'product'}},
								{kind: 'Field', name: {kind: 'Name', value: 'amount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'count'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'typeAmount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'typeCount'}},
				],
			},
		},
	],
} as unknown as DocumentNode<PaymentsFragment, unknown>;
export const BroadcastsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Broadcasts'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'PaginationInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'broadcasts'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {kind: 'Name', value: 'BROADCAST_BASE'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'BROADCAST_BASE'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BroadcastEntity'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'status'}},
					{kind: 'Field', name: {kind: 'Name', value: 'imagePath'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'text'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'errorCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'successCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonText'}},
				],
			},
		},
	],
} as unknown as DocumentNode<BroadcastsQuery, BroadcastsQueryVariables>;
export const CreateBroadcastDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateBroadcast'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'CreateBroadcastInput'},
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'file'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Upload'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createBroadcast'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'file'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'file'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'BROADCAST_BASE'},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'BROADCAST_BASE'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BroadcastEntity'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'status'}},
					{kind: 'Field', name: {kind: 'Name', value: 'imagePath'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'text'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'errorCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'successCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonText'}},
				],
			},
		},
	],
} as unknown as DocumentNode<CreateBroadcastMutation, CreateBroadcastMutationVariables>;
export const TestBroadcastDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'TestBroadcast'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TestBroadcastInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'testBroadcast'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<TestBroadcastMutation, TestBroadcastMutationVariables>;
export const EditBroadcastDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'EditBroadcast'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'EditBroadcastInput'},
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'file'}},
					type: {kind: 'NamedType', name: {kind: 'Name', value: 'Upload'}},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'editBroadcast'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'file'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'file'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'BROADCAST_BASE'},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'BROADCAST_BASE'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BroadcastEntity'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'status'}},
					{kind: 'Field', name: {kind: 'Name', value: 'imagePath'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'text'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'errorCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'successCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonText'}},
				],
			},
		},
	],
} as unknown as DocumentNode<EditBroadcastMutation, EditBroadcastMutationVariables>;
export const RemoveBroadcastDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'RemoveBroadcast'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'removeBroadcast'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: {kind: 'Name', value: 'BROADCAST_BASE'},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'BROADCAST_BASE'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'BroadcastEntity'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{kind: 'Field', name: {kind: 'Name', value: 'id'}},
					{kind: 'Field', name: {kind: 'Name', value: 'status'}},
					{kind: 'Field', name: {kind: 'Name', value: 'imagePath'}},
					{kind: 'Field', name: {kind: 'Name', value: 'name'}},
					{kind: 'Field', name: {kind: 'Name', value: 'text'}},
					{kind: 'Field', name: {kind: 'Name', value: 'recipientCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'errorCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'successCount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'createdAt'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonUrl'}},
					{kind: 'Field', name: {kind: 'Name', value: 'buttonText'}},
				],
			},
		},
	],
} as unknown as DocumentNode<RemoveBroadcastMutation, RemoveBroadcastMutationVariables>;
export const StartBroadcastDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'StartBroadcast'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'startBroadcast'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'id'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<StartBroadcastMutation, StartBroadcastMutationVariables>;
export const BookedSuppliesStatDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'BookedSuppliesStat'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'bookedSuppliesStat'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'date'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'bookedCount'},
											},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'groupedByTime'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<BookedSuppliesStatQuery, BookedSuppliesStatQueryVariables>;
export const TelegramUsersDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'TelegramUsers'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'TelegramUsersArgs'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'telegramUsers'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'isActive'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'firstName'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'lastName'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'username'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'firstActionAt'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'lastActionAt'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<TelegramUsersQuery, TelegramUsersQueryVariables>;
export const GetUserDataDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetUserData'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'TelegramUserInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getUserData'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'isActive'}},
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
								{kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
								{kind: 'Field', name: {kind: 'Name', value: 'username'}},
								{kind: 'Field', name: {kind: 'Name', value: 'firstActionAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'lastActionAt'}},
								{kind: 'Field', name: {kind: 'Name', value: 'activeToDate'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'isSubscriptionActive'},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'freeSupplyCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'autoPayment'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetUserDataQuery, GetUserDataQueryVariables>;
export const GetUserPaymentsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetUserPayments'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserPayments'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getUserPayments'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'createdAt'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'amount'}},
											{kind: 'Field', name: {kind: 'Name', value: 'orderId'}},
											{kind: 'Field', name: {kind: 'Name', value: 'status'}},
											{kind: 'Field', name: {kind: 'Name', value: 'type'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'autoPayment'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetUserPaymentsQuery, GetUserPaymentsQueryVariables>;
export const GetWbPaidSupplyTracksDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetWbPaidSupplyTracks'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserDateRange'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getWbPaidSupplyTracks'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'createdAt'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'enabled'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'warehouseName'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'supplyType'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'coefficient'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'supplyId'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'supplyOrderText'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'status'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'resultStatus'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'error'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetWbPaidSupplyTracksQuery, GetWbPaidSupplyTracksQueryVariables>;
export const GetWbSupplyTracksDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetWbSupplyTracks'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserDateRange'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getWbSupplyTracks'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'createdAt'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'enabled'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'warehouseName'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'supplyType'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'coefficient'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'dates'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'resultStatus'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetWbSupplyTracksQuery, GetWbSupplyTracksQueryVariables>;
export const GetOzonSupplyTracksDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetOzonSupplyTracks'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserDateRange'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getOzonSupplyTracks'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'enabled'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'supplyOrderId'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'supplyOrderText'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'resultStatus'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetOzonSupplyTracksQuery, GetOzonSupplyTracksQueryVariables>;
export const UserSetSettingsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'UserSetSettings'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserSettingsInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'userSetSettings'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UserSetSettingsMutation, UserSetSettingsMutationVariables>;
export const DisableWbSupplyDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DisableWbSupply'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'SupplySettingsInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'disableWbSupply'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<DisableWbSupplyMutation, DisableWbSupplyMutationVariables>;
export const DisableOzonSupplyDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DisableOzonSupply'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'SupplySettingsInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'disableOzonSupply'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<DisableOzonSupplyMutation, DisableOzonSupplyMutationVariables>;
export const DisableWbPaidSupplyDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DisableWbPaidSupply'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'SupplySettingsInput'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'disableWbPaidSupply'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<DisableWbPaidSupplyMutation, DisableWbPaidSupplyMutationVariables>;
export const DeleteWbPaidSupplyDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'DeleteWbPaidSupply'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'SupplyDeleteInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'deleteWbPaidSupply'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<DeleteWbPaidSupplyMutation, DeleteWbPaidSupplyMutationVariables>;
export const CreateCardSubscriptionPaymentDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateCardSubscriptionPayment'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserCreateSubscriptionPayment'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createCardSubscriptionPayment'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	CreateCardSubscriptionPaymentMutation,
	CreateCardSubscriptionPaymentMutationVariables
>;
export const CreateCardSupplyPaymentDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateCardSupplyPayment'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserCreateSupplyPayment'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createCardSupplyPayment'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	CreateCardSupplyPaymentMutation,
	CreateCardSupplyPaymentMutationVariables
>;
export const CreateSuccessSubscriptionPaymentDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateSuccessSubscriptionPayment'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserCreateSubscriptionPayment'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createSuccessSubscriptionPayment'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	CreateSuccessSubscriptionPaymentMutation,
	CreateSuccessSubscriptionPaymentMutationVariables
>;
export const CreateSuccessSupplyPaymentDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'CreateSuccessSupplyPayment'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: {kind: 'Name', value: 'TelegramUserCreateSupplyPayment'},
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'createSuccessSupplyPayment'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	CreateSuccessSupplyPaymentMutation,
	CreateSuccessSupplyPaymentMutationVariables
>;
export const LoginDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'Login'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'LoginInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'login'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'user'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'email'}},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'token'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const RegistrationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'Registration'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'RegisterInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'registration'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<RegistrationMutation, RegistrationMutationVariables>;
export const LogoutDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'Logout'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{kind: 'Field', name: {kind: 'Name', value: 'logout'}}],
			},
		},
	],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'Me'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'me'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'id'}},
								{kind: 'Field', name: {kind: 'Name', value: 'email'}},
								{kind: 'Field', name: {kind: 'Name', value: 'name'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const MainStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'MainStats'},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'mainStats'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'activeUserCount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'notActiveUserCount'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'lastMonthActiveUserCount'},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'lastMonthActionCount'},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<MainStatsQuery, MainStatsQueryVariables>;
export const UserRegistrationsStatDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'UserRegistrationsStat'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'userRegistrationsStat'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'groupedByTime'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'date'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'registrationCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'unsubscribedCount'},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UserRegistrationsStatQuery, UserRegistrationsStatQueryVariables>;
export const TelegramUsersActionsStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'TelegramUsersActionsStats'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'telegramUsersActionsStats'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'groupedByTime'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'date'}},
											{kind: 'Field', name: {kind: 'Name', value: 'count'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	TelegramUsersActionsStatsQuery,
	TelegramUsersActionsStatsQueryVariables
>;
export const UniqueTelegramUsersActivityDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'UniqueTelegramUsersActivity'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'uniqueTelegramUsersActivity'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'groupedByTime'}},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'date'}},
											{kind: 'Field', name: {kind: 'Name', value: 'count'}},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UniqueTelegramUsersActivityQuery,
	UniqueTelegramUsersActivityQueryVariables
>;
export const PasswordResetDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'PasswordReset'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'passwordReset'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'email'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'email'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PasswordResetMutation, PasswordResetMutationVariables>;
export const SetNewPasswordDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'SetNewPassword'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'NewPasswordInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'setNewPassword'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SetNewPasswordMutation, SetNewPasswordMutationVariables>;
export const PaymentStatV3Document = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'PaymentStatV3'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateRangeInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						alias: {kind: 'Name', value: 'paymentsStats'},
						name: {kind: 'Name', value: 'paymentStatV3'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'date'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'sumAmount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'sumCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'SUPPLY'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'PAYMENTS'},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'SUBSCRIPTION'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'PAYMENTS'},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'itemsSum'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'date'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'sumAmount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'sumCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'SUPPLY'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'PAYMENTS'},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'SUBSCRIPTION'},
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {kind: 'Name', value: 'PAYMENTS'},
														},
													],
												},
											},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'supplySum'}},
								{kind: 'Field', name: {kind: 'Name', value: 'subscriptionSum'}},
								{kind: 'Field', name: {kind: 'Name', value: 'totalSum'}},
								{kind: 'Field', name: {kind: 'Name', value: 'groupedByTime'}},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: {kind: 'Name', value: 'PAYMENTS'},
			typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PaymentsStatTypeV3'}},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'payments'},
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{kind: 'Field', name: {kind: 'Name', value: 'product'}},
								{kind: 'Field', name: {kind: 'Name', value: 'amount'}},
								{kind: 'Field', name: {kind: 'Name', value: 'count'}},
							],
						},
					},
					{kind: 'Field', name: {kind: 'Name', value: 'typeAmount'}},
					{kind: 'Field', name: {kind: 'Name', value: 'typeCount'}},
				],
			},
		},
	],
} as unknown as DocumentNode<PaymentStatV3Query, PaymentStatV3QueryVariables>;
export const GetUtmStatsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: {kind: 'Name', value: 'GetUtmStats'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'UtmStatsArgs'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'getUtmStats'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: {kind: 'Name', value: 'items'},
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{kind: 'Field', name: {kind: 'Name', value: 'id'}},
											{kind: 'Field', name: {kind: 'Name', value: 'utmName'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'utmSource'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'utmCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'createdAt'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'usersCount'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'usersWithPayments'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'paymentsTotal'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'amountTotal'},
											},
											{kind: 'Field', name: {kind: 'Name', value: 'utmCost'}},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'registrationCost'},
											},
											{
												kind: 'Field',
												name: {kind: 'Name', value: 'paymentsUserCost'},
											},
										],
									},
								},
								{kind: 'Field', name: {kind: 'Name', value: 'total'}},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<GetUtmStatsQuery, GetUtmStatsQueryVariables>;
export const UpdateUtmStatDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'UpdateUtmStat'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'UtmStatInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'updateUtmStat'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UpdateUtmStatMutation, UpdateUtmStatMutationVariables>;
export const AddUtmSourceDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: {kind: 'Name', value: 'AddUtmSource'},
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
					type: {
						kind: 'NonNullType',
						type: {kind: 'NamedType', name: {kind: 'Name', value: 'UtmStatInput'}},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: {kind: 'Name', value: 'addUtmSource'},
						arguments: [
							{
								kind: 'Argument',
								name: {kind: 'Name', value: 'input'},
								value: {kind: 'Variable', name: {kind: 'Name', value: 'input'}},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AddUtmSourceMutation, AddUtmSourceMutationVariables>;
