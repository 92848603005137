import React, {useMemo} from 'react';

import NewAndDeletedUsersChart from 'components/dashboard/NewAndDeletedUsersChart';
import TelegramUsersActionsStatChart from 'components/dashboard/TelegramUsersActionsStatChart';
import UniqueTelegramUsersActivityChart from 'components/dashboard/UniqueTelegramUsersActivityChart';
import AppWrapper from 'components/common/AppWrapper';
import {useMainStatsQuery} from 'api/users';
import BookedSuppliesStatChart from 'components/dashboard/BookedSuppliesStatChart';

export enum BlocksDisplayEnum {
	LastMonthActiveUserCount = 'Пользователей за месяц',
	ActiveUserCount = 'Всего пользователей',
	NotActiveUserCount = 'Отписавшихся пользователей',
	LastMonthActionCount = 'Действий за месяц',
}

const DashboardPage: React.FC = () => {
	const {data: dataMainStats, loading} = useMainStatsQuery();

	const blocks = useMemo(
		() => [
			{
				label: BlocksDisplayEnum.LastMonthActiveUserCount,
				value: dataMainStats?.mainStats.lastMonthActiveUserCount,
				loading,
			},
			{
				label: BlocksDisplayEnum.ActiveUserCount,
				value: dataMainStats?.mainStats.activeUserCount,
				loading,
			},
			{
				label: BlocksDisplayEnum.NotActiveUserCount,
				value: dataMainStats?.mainStats.notActiveUserCount,
				loading,
			},
			{
				label: BlocksDisplayEnum.LastMonthActionCount,
				value: dataMainStats?.mainStats.lastMonthActionCount,
				loading,
			},
		],
		[
			dataMainStats?.mainStats.activeUserCount,
			dataMainStats?.mainStats.lastMonthActionCount,
			dataMainStats?.mainStats.lastMonthActiveUserCount,
			dataMainStats?.mainStats.notActiveUserCount,
			loading,
		],
	);

	return (
		<AppWrapper title={'Главная'} blocksStat={blocks}>
			<NewAndDeletedUsersChart />

			<BookedSuppliesStatChart />

			<TelegramUsersActionsStatChart />

			<UniqueTelegramUsersActivityChart />
		</AppWrapper>
	);
};

export default DashboardPage;
